:root{
    --black:#000000;
    --white:#FFFFFF;
    --accent-color:#F62680;
}

/* Testimonial Styles */
.text-container{
    display: flex;
    height: 100dvh;
   padding: 30px;
    flex-direction: column;
    justify-content: center;
    font-family: "secondary-font";
}

.pagnation-dot{
    cursor: pointer;
    margin: 10px;
}
.dot1{
    margin-left: 0;
}

/*Testimonial Responsive Design */
@media (min-width:100px) and (max-width:300px) {

    .text-message{
        font-size: 16px;
    }
}
@media screen and (max-height:414px){

    .text-message{
        font-size: 16px;
    } 
}

@media (min-width:301px) and (max-width:667px) {

    .text-message{
        font-size: 20px;
    }
}

/* Slider Styles */
.work-container{
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "secondary-font";
    overflow: hidden !important;
}
.work-title{
    margin: 0;
}
.work-title, .work-caption{
    padding-left: 30px;
}
.work-caption{
    color: grey;
    padding-bottom: 30px;
}
.project-slider{
    display: flex;
    gap: 1em;
   overflow: auto;
   scroll-snap-type: x mandatory;
   scrollbar-width: none;
   position: relative;
}
.project-slider::-webkit-scrollbar {
    display: none;
  }
  
.project-slide{
    scroll-snap-align: center;
    width: 55%;
    height: 70dvh;
    flex-shrink: 0;
    border-radius: 40px;
    /* border: 1px solid var(--black); */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--white);
    text-decoration: none;
}
.project-name{
    font-size: 25px;
    margin: 0;
}
.project-wrapper{
    margin: 30px;
}

/* Images */
.slide1{
    margin-left: 10px;
background-image: url('https://s3.amazonaws.com/shecodesio-production/uploads/files/000/078/505/original/pixelcut_11.JPG?1682275025');
background-size: cover;
}
.slide2{
    background-image: url('../assets/oree1.png');
    background-size: cover;
}
.slide3{
    background-image: url('../assets/hair2.png');
    background-size: cover;
    margin-right: 10px;
}
.slide4{
    background-image: url('../assets/moojy2.jpg');
    background-size:cover ;
}
/* Responsive Design */
@media screen and (max-width:667px){
    .project-slide{
        width: 100%;
    }   
}
@media screen and (max-height:414px){
    .project-slide{
        width: 55%;
    }
}
@media only screen 
    and (device-width : 912px) 
    and (device-height: 1368px) { 
        .project-slide{
            width: 100%;
        }  

     }

     /* Contact Styles */
     .contact-wrapper{
        min-height: 100dvh;
        background-color: #F1F1F1;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     }
     .form-container{
        background-color: var(--black);
        width: 95%;
        min-height: 70dvh;
        padding: 40px;
        border-radius: 10px;
        display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
     }
   .contact-heading{
    font-size: 27px;
    font-family: "theme-font";
   }
     .color{
        color: var(--accent-color);
     }
     .socialMedia{
        align-self: flex-end;
        margin: 10px;
     }
     .socialMedia a{
        color: var(--accent-color);
        font-size: 25px;
        margin: 10px;
       display: inline-block;
     }
.form{
    display: flex;
    flex-direction: column;
    width: 80%;
}
.form input, .form textarea{
    font-family: Arial Black;
    margin: 10px 0;
    padding: 10px;
    outline: none;
    color: #000;
}
.form-btn{
    color: #000;
    background-color: #fff;
    border: none;
    font-family: Arial Black;
    width: 100px;
    padding: 10px 20px;
    cursor: pointer;
}

.webCreds{
    color: var(--accent-color);
    font-family: "secondary-font";
    font-size: 10px;
    align-self: flex-end;
    margin-right: 15px;
}
        /* Form Thank You Page */
        .form-thanks{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #000;
            color: #fff;
            min-height: 100dvh;
         }
         .form-thanks h1{
            font-family: "theme-font";
         }
         .form-thanks p{
            font-family: "secondary-font";
         }
         .form-thanks svg{
            font-size: 50px;
            color:var(--accent-color);
         }
