:root{
    --black:#000000;
    --white:#FFFFFF;
    --accent-color:#F62680;
}


.about-container{
    background-color:var(--white) ;
    color: grey;
    font-family: "secondary-font";
  padding: 0px 50px;
display: flex;
flex-direction: column;
justify-content: center;
min-height: 100dvh;
}

.about-main-heading, .about-second-heading, .about-content-title{
    color: var(--black);
}
.about-main-heading{
    font-family: "theme-font";   
}
/* Responsive Design */
@media (min-width:100px) and (max-width:300px) {
    .about-main-heading{
        font-size: 20px;
    }  
    .about-second-heading{
        font-size: 20px;
    }
}

@media screen and (max-height:414px){
    .about-main-heading{
        font-size: 20px;
    }  
    .about-second-heading{
        font-size: 20px;
    }  
}
