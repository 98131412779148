:root{
    --black:#000000;
    --white:#FFFFFF;
    --accent-color:#F62680;
}

/* Brand Font Imports */
@font-face {
    font-family: "boy-blondey";
    src: url('../assets/brandFonts/bbfont.otf');
}
@font-face {
    font-family: "house-of-hair-one";
    src: url('../assets/brandFonts/hair1.otf');
}
@font-face {
    font-family: "moojy-one";
    src: url('../assets/brandFonts/moojy1.ttf');
}
@font-face {
    font-family: "moojy-two";
    src: url('../assets/brandFonts/moojy2.ttf');
}

.brand-wrapper{
    display: grid;
    grid-template-columns: 50% 50%;
   grid-auto-rows: 50dvh;
   grid-gap: 2px;
}

.brand-card{
    background-color: #F1F1F1;
    color: var(--black);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; 
}
.brand-content{
   background-color: var(--white);
   color: var(--black);
   position: absolute;
   top: 0;
   bottom: 0;
   opacity: 0;
   transition: all 300ms ease-in-out;
   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "secondary-font";
    padding: 10px;
}

.brand-card:hover > .brand-content{
    opacity: 1;
}

.brand-oree{
    font-family: "theme-font"; 
}
.brand-bb{
    font-family: "boy-blondey";
}
.brand-hair{
    font-family: "house-of-hair-one";
}
.brand-moojy{
    font-family: "moojy-one";
}
.moojy-font2{
    font-family: "moojy-two";
}
/* Responsive Design */
@media (min-width:100px) and (max-width:300px) {
    .brand-card h1{
        font-size: 16px;
    }
    .brand-content{
        font-size: 10px;
    }
}
@media screen and (max-height:414px){
    .brand-card h1{
        font-size: 16px;
    }
    .brand-content{
        font-size: 10px;
    }  
}