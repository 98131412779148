:root{
    --black:#000000;
    --white:#FFFFFF;
    --accent-color:#F62680;
}

       div.hamburger-react{
      z-index: 7 !important;
       }
     .nav-menu.active{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
       position: absolute;
       top: 0;
       bottom: 0;
       left: 0;
       width: 100%;
       height: 100dvh;
     }

     .nav-link{
        text-decoration: none;
        color: var(--black) !important;
        font-family: "theme-font";
        font-size: 50px;
        margin: 20px;
     }
     .emptyDiv{
        background-color: transparent !important;
        color: black;
     }

     /* Responsive Design */
@media (min-width:100px) and (max-width:900px) {
.nav-link{
    font-size: 30px;
}
}

@media screen and (max-height:425px){
    .nav-link{
        font-size: 20px;
    }}