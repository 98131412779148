:root{
    --black:#000000;
    --white:#FFFFFF;
    --accent-color:#F62680;
}

*{
    box-sizing: border-box;
}
html {
    overflow-x: hidden;
  }
body, html {
    border: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
}

/* Homepage Styles */
.home-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.home-section{
    background-color: var(--black);
    color: var(--white);
    width: 50%;
  height: 100dvh;
   position: sticky;
   top: 0;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
  padding: 30px 0px 0px 30px;
   font-family: "secondary-font";
}
.home-section a{
    color: var(--accent-color);
}

h1.home-title{
    font-family: "theme-font";
    font-size: 40px;
}
.home-button{
    display: inline-block;
    text-decoration: none;
    color: var(--white) !important;
    border: 1px solid var(--white);
    padding: 20px;
    border-radius: 50px;
}
.home-button:hover{
    background-color: var(--white);
    color: var(--black) !important;
}
/* Scroll Animation Styles */
.animation-container{
    width: 50%;
    position: relative;
}
::-webkit-scrollbar {
    display: none;
}
.slide{
    min-height: 100dvh;
    position: sticky;
    top: 0;
}
.video{
    background-color: #02070F;
    background-image: url('../assets/brain.gif');
    background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.about, .brands, .work{
    background-color: var(--white);
}
.testimonials{
    color: var(--white);
    background-color: var(--black);
}
/* Responsive Design */
@media (min-width:100px) and (max-width:300px) {
  
    .home-container{
        flex-direction: column;
    }
    .home-section{
        width: 100%;
        justify-content: space-between;
    }
    h1.home-title{
        font-size: 20px;
    }
    .home-button{
        padding: 10px;
    }
    .animation-container{
        width: 100%;
    }
    .about{
        position: relative;
    }  

}
@media screen and (max-height:414px){
    .slide{
        position: relative;
    }
    .home-container{
        flex-direction: column;
    }
    .home-section{
        width: 100%;
        position: relative;
        justify-content: space-between;
        padding: 50px;
    }
    h1.home-title{
        font-size: 20px;
    }
    .home-button{
        padding: 10px;
    }
    .animation-container{
        width: 100%;
    }
    .about{
        position: relative;
    } 

}
@media (min-width:300px) and (max-width:900px) {
 
    .home-container{
        flex-direction: column;
    }
    .home-section{
        width: 100%;
    }
    .animation-container{
        width: 100%;
    }
    .about{
        position: relative;
    }
  
}
/* Nest Hub Device ONLY */
@media only screen 
    and (device-width : 1024px) 
    and (device-height: 600px) { 
        .about{
            position: relative;
        }
     }

   